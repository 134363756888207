@import '../../styles/variables.less';

.m-header {
  height: 80px;
  background-color: #fff;

  &-fixed {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 1px 5px #f0f2fa;
  }

  .m-boxModel {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;

    .header-logo {
      min-width: 180px;
      width: 180px;
      height: 70px;

      a {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
      }

      img,
      .img-box {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .header-right {
      display: flex;
      align-items: center;

      .header-menu {
        display: flex;
        margin-right: 10px;
        margin-bottom: 0px;

        .item-li {
          position: relative;
          display: flex;
          min-width: 80px;
          margin-left: 30px;
          font-weight: bold;
          font-size: 16px;
          height: 76px;
          line-height: 76px;

          a,
          span {
            position: relative;
            z-index: 2;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: @c-mainText;
          }

          &::before {
            content: '';
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
            width: 0;
            height: 2px;
            .transition(0.3s all);
            opacity: 0;
          }

          &:hover,
          &.active {
            color: @c-mainColor;
            cursor: pointer;

            &::before {
              width: 100%;
              background-color: @c-mainColor;
              opacity: 1;
            }
          }
        }
      }

      .header-email {
        display: flex;
        align-items: center;
        word-break: break-all;
        color: @c-mainText;
        font-weight: bold;
        position: relative;
        height: 72px;
        margin-left: 10px;
        max-width: 210px;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
          cursor: pointer;
          display: inline-block;
          height: 100%;
          line-height: 72px;
        }

        .k-consoleMenuStyle {
          width: 100%;
          box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
          position: absolute;
        }
      }
    }

    .header-toolbar {
      display: none;
    }
  }
}

.header-btn {
  display: flex;
  align-items: center;

  label {
    position: relative;
    display: inline-block;
    min-width: 90px;
    height: 38px;
    margin-left: 10px;
    border: 1px solid transparent;
    background-color: @c-mainColor;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    line-height: 38px;

    a {
      position: relative;
      z-index: 2;
      display: flex;
      color: #fff;
      align-items: center;
      justify-content: center;
      padding: 0px 8px;

      &:hover {
        color: #fff;
      }
    }

    &.u-hover {

      img,
      .img-box {
        width: 18px;
        margin-right: 6px;
      }

      .f-btn(rgba(255, 255, 255, 0.3));
    }

    &.u-active {
      background: #fff;
      border: 1px solid @c-mainColor;

      a {
        color: @c-mainColor;
      }

      &:hover {
        background: @c-mainColor;

        a {
          color: #fff;
        }
      }

      .f-btn(rgba(255, 255, 255, 0.3));
    }

    &.u-console {
      a {
        white-space: nowrap;
      }

      .f-btn(rgba(255, 255, 255, 0.3));
    }
  }
}

.header-subMenu {
  .ant-popover-arrow {
    width: 28px;
    height: 28px;
    top: 1px;

    .ant-popover-arrow-content {
      width: 28px;
      height: 28px;
    }
  }

  .ant-popover-inner {
    display: flex;
    align-items: center;
    border-radius: 14px;
  }

  .item-ul {
    display: flex;
    align-items: center;
    padding: 0px;
    margin: 0;

    &>.item-li {
      margin: 0;
      padding: 20px;
      position: relative;
      z-index: 2;
      min-width: 260px;
      height: 80px;

      &>a {
        display: flex;
        align-items: center;
        color: @c-mainText;
        width: 100%;
        height: 100%;
        font-size: 16px;

        img,
        .img-box {
          width: 46px;
          height: auto;
          object-fit: cover;
          margin-right: 30px;
        }

        &:hover {
          color: @c-mainColor;
        }
      }

      &:hover {
        background: #eff7ff;
      }
    }
  }
}

.k-emailStyle {

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    padding: 10px 12px;
    color: @c-mainText;

    &:hover {
      background-color: @c-mainColor;
      color: #fff;
      border-radius: 3px;
    }

    a:hover {
      color: #fff;
    }
  }

  .red {
    color: red;
  }
}

.m-header-drawer {
  display: none;
}

@media screen and (max-width: 1440px) {
  .m-header {
    .m-boxModel {
      .header-logo {
        min-width: 180px * @media1024;
        width: 180px * @media1024;
        height: 70px * @media1024;
      }

      .header-right {
        .header-menu {
          margin-right: 10px;

          .item-li {
            min-width: 50px;
            max-width: 90px;
            margin-left: 20px * @media1024;
          }
        }

        .header-email {
          margin-left: 10px * @media1024;
          max-width: 210px * @media1024;
        }
      }
    }
  }

  .header-subMenu {
    .item-ul {
      &>.item-li {
        min-width: 260px * @media1024;
        height: 80px * @media1024;

        &>a {
          font-size: 16px;

          img,
          .img-box {
            width: 46px * @media1024;
            margin-right: 30px * @media1024;
          }
        }

        &:first-of-type {
          margin-right: 40px;
        }

        &:hover {
          background: #eff7ff;
        }
      }
    }
  }

  .header-btn {
    label {
      height: 38px;
      margin-left: 10px * @media1024;
      line-height: 38px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .m-header {
    &.u-fixed {
      max-width: 1024px;
    }
  }
}

@media screen and (max-width: 768px) {
  .m-header {
    height: 7.5rem;

    &.u-fixed {
      width: 100%;
    }

    .m-boxModel {
      margin: 0;

      .header-logo {
        min-width: 13.125rem;
        width: 13.125rem;
        height: auto;
      }

      .header-right {
        display: none;
      }

      .header-toolbar {
        display: flex;
        align-items: center;
        margin-left: 1.25rem;

        // position: absolute;
        // right: 20px;
        img,
        .img-box {
          width: 2.25rem;
          height: 2.25rem;
        }
      }

      .header-middle {
        display: block;
        width: 3.25rem;
        height: auto;
      }
    }
  }

  // 侧边栏
  .m-header-drawer {
    display: block;

    .ant-drawer-header {
      height: 7.5rem;
      padding: 0 3.125rem;

      .ant-drawer-header-title {
        display: flex;
        flex-direction: row-reverse;
      }

      .ant-drawer-title {
        line-height: 7.5rem;
        padding-top: 3.75rem;

        img,
        .img-box {
          width: 13.125rem;
          height: auto;
        }
      }

      .ant-drawer-close {
        top: 10px;
        padding: 10px;
        font-size: 2.25rem;
        color: @c-iconColor;
      }
    }

    .ant-drawer-body {
      padding: 0;
    }

    .item-ul,
    .header-menu {
      margin-right: 0px;
      width: 100%;
      flex-direction: column;

      &>.item-li {
        width: 100%;
        margin-left: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 30rem / @media768;
        color: @c-mainText;
        height: 140rem / @media768;
        line-height: 140rem / @media768;
        padding: 0 3.125rem;

        &:hover,
        &.active {
          a {
            color: @c-mainColor;
          }

          &::before {
            background-color: transparent;
          }
        }

        &.k-child-vps {
          justify-content: normal;

          img,
          .flag,
          .img-box {
            margin-right: 10rem / @media768;
            width: 60rem / @media768;
            height: 45rem / @media768;
          }
        }

        a {
          justify-content: flex-start;
          color: @c-mainText;
          width: 100%;
        }

        &:not(:first-of-type) {
          border-top: 1px solid transparent;
        }
      }

      .hasChildren {
        &::after {
          content: '';
          display: inline-block;
          width: 24rem / @media768;
          height: 24rem / @media768;
          border-right: 2px solid #ccc;
          border-top: 2px solid #ccc;
          transform: rotate(135deg);
          transition: all 100ms;
        }

        &.show {
          &::after {
            transform: rotate(315deg);
            transition: all 100ms;
          }
        }
      }
    }

    .header-setLocale,
    .email-mobile {
      border-top: 1px solid #eee;

      &>div,
      &>li {
        font-size: 28rem / @media768;
        width: 100%;
        margin-left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #121212;
        height: 8.75rem;
        line-height: 8.75rem;
        padding: 0 3.125rem;
      }

      ul {
        padding-left: 1em;
        margin-right: 0;
        width: 100%;

        li {
          width: 100%;
          font-size: 28rem / @media768;
          color: #666;
          height: 100rem / @media768;
          line-height: 100rem / @media768;
          font-weight: normal;
          padding: 0 3.125rem;
          border-top: none !important;
          margin-left: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;

          img,
          .img-box {
            display: block;
            width: auto;
            //height: 18px;
          }
        }

        .red {
          color: red;
        }
      }

      .hasChildren {
        &::after {
          content: '';
          display: inline-block;
          width: 24rem / @media768;
          height: 24rem / @media768;
          border-right: 2px solid #ccc;
          border-top: 2px solid #ccc;
          transform: rotate(135deg);
          transition: all 100ms;
        }

        &.show {
          &::after {
            transform: rotate(315deg);
            transition: all 100ms;
          }
        }
      }
    }

    .header-btn {
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      background: #fff;
      padding: 0.5rem 3.125rem 5rem;
      z-index: 999;

      label {
        width: 46%;
        height: 6.25rem;
        font-size: 1.875rem;
        line-height: 6.25rem;
        margin-left: 0px;
      }

      .u-hover {

        img,
        .img-box {
          width: 2.25rem;
        }
      }
    }
  }

  .header-subMenu {
    display: none;
  }
}