@import '../../styles/variables.less';

//公共布局 - 脚部
.m-footer-top {
  // background-color: #061656;
  background: linear-gradient(0deg, #6064ff 0%, #00f3b7 100%);

  a {
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }

  .m-boxModel {
    display: flex;

    dl {
      flex: 1;
      padding: 60px 10px;
      line-height: 160%;

      &.flex-1-5 {
        flex: 1.5;
      }

      &.flex-2 {
        flex: 2;
      }

      dt {
        margin-bottom: 15px;
        font-size: 18px;
        font-weight: bold;
        color: #fff;
      }

      dd {
        margin-bottom: 15px;
        color: rgba(255, 255, 255, 0.7);

        &:hover {
          color: #fff;
        }

        img,
        .img-box {
          display: inline-block;
          vertical-align: middle;
          width: 24px;
          height: 24px;
          margin-right: 5px;
          margin-top: -3px;
        }
      }
    }

    .m-footer-top-form {
      .ant-form-item {
        margin-bottom: 10px;
      }

      .ant-form-item-explain {
        width: 200px;
      }
    }

    .m-footer-top-collapse {
      .adm-list {
        background: transparent;

        &-body {
          background-color: transparent;
          border: none;

          &-inner {
            &>.adm-list-item {
              background-color: transparent;
              margin-bottom: 15px;
              color: rgba(255, 255, 255, 0.7);
              padding: 0;

              &:hover {
                color: #fff;

                .adm-list-item-content-arrow {
                  color: #fff;
                }
              }

              &::after {
                display: none;
              }

              .adm-list-item-content {
                border: none;
              }

              .adm-list-item-content-main {
                padding: 0;
                font-size: 14px;
              }

              .adm-list-item-content-arrow {
                font-size: 12px;
                color: rgba(255, 255, 255, 0.9);
              }
            }

            &>.adm-collapse-panel-content {
              .adm-list-item {
                background-color: transparent;

                .adm-list-item-content {
                  border: none;

                  .adm-list-item-content-main {
                    padding: 0;

                    &>div {
                      color: rgba(255, 255, 255, 0.7);
                      margin-bottom: 15px;

                      &:hover {
                        color: #fff;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .m-footer-top-location {
      background: transparent;
      color: rgba(255, 255, 255, 0.7);
      border: none;

      .ant-menu-submenu-title {
        padding-left: 0 !important;
        line-height: 1.5;
        margin-bottom: 15px;
        margin-top: 0px;
        height: auto;

        &:hover {
          color: #fff;
          background: transparent;
        }
      }

      .ant-menu-submenu-arrow {
        color: #fff;
      }

      .ant-menu-submenu-active,
      .ant-menu-submenu-selected {
        color: #fff;
        background: transparent;
      }

      .ant-menu-sub {
        background: transparent;

        &>li {
          padding-left: 20px !important;
          margin-bottom: 15px;
          height: auto;
          line-height: 1.5;
          padding: 0px;
          background: transparent;

          a {
            color: rgba(255, 255, 255, 0.7);

            &:hover {
              color: #fff;
            }
          }
        }
      }

      .ant-menu-item-selected {
        background: transparent;

        &::after {
          display: none;
        }
      }
    }
  }
}

.m-footer-bottom {
  display: flex;
  height: 45px;

  .m-boxModel {
    padding: 0 10%;
    align-self: center;
    text-align: center;
    color: #999999;
    line-height: 160%;
  }
}

@media screen and (max-width: 768px) {
  .m-footer-top {
    display: none;
  }

  .m-footer-bottom {
    height: 140rem / @media768;

    .m-boxModel {
      padding: 0 50rem / @media768;
      font-size: 22rem / @media768;
    }
  }
}