@font-face {
  font-family: 'iconfont'; /* Project id 2747972 */
  src: url('../static/fonts/iconfont.eot?t=1629099988376'); /* IE9 */
  src: url('../static/fonts/iconfont.eot?t=1629099988376#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../static/fonts/iconfont.woff2?t=1629099988376')
      format('woff2'),
    url('../static/fonts/iconfont.woff?t=1629099988376') format('woff'),
    url('../static/fonts/iconfont.ttf?t=1629099988376') format('truetype'),
    url('../static/fonts/iconfont.svg?t=1629099988376#iconfont') format('svg');
  font-display: swap;
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-yuyan:before {
  content: '\e62c';
}
