
.f-pr {
  position: absolute;
  right: 0;
}
.f-pl {
  position: absolute;
  left: 0;
}
.f-tc {
  text-align: center;
}
.f-tr {
  text-align: right;
}
/* // 灰色 */
.c-gray {
  color: #bdbdbd;
}
/* // 绿色 */
.c-green {
  color: #00a870;
}
/* // 红色 */
.c-red {
  color: #e34d59;
}
/* // 蓝色 */
.c-blue {
  color: #0f7aff;
}
/* // 橘色 */
.c-orange {
  color: #FF8417;
}

@media screen and (max-width: 1366px) {
  
}
@media screen and (max-width: 1024px) {
  
}
@media screen and (max-width: 768px) {
 
}
