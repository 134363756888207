.k-setLocale {
  position: relative;
  min-width: 100px;
  margin-left: 10px;
  height: 72px;
  .k-setLocaleCont {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #1890ff;
    height: 100%;
    > i {
      font-size: 24px;
    }
    > span {
      line-height: 15px;
      padding-left: 5px;
    }
    &:hover {
      font-weight: bold;
      .icon-partner {
        background: url(../../static/images/icon-partner-on.png) no-repeat center;
        background-size: 100%;
      }
    }
    .icon-partner {
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url(../../static/images/icon-partner.png) no-repeat center;
      background-size: 100%;
    }
  }
  .k-setLocaleSelect {
    width: 100%;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
    position: absolute;
    .k-setLocaleItem {
      // background-color: #1890ff;
      padding: 5px 0;
      text-align: center;
    }
  }
}
.k-setlocaleStyle {
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    padding: 10px 12px;
    &:hover {
      background-color: #1890ff;
      color: #ffffff;
      border-radius: 3px;
    }
  }
}
.k-setPartnerStyle .ant-dropdown-menu {
  padding: 10px 0;
  .ant-dropdown-menu-title-content {
    img, .img-box {
      display: block;
      width: auto;
      min-width: max-content;
      min-height: 18px !important;
      height: 18px !important;
    }
  }
}
@media screen and (max-width: 1440px) {
  .k-setLocale {
    min-width: auto;
    .k-setLocaleCont > span {
      display: none;
    }
  }
}
