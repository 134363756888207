.k-confirm-modal {
  &-title {
    padding: 11px 0 35px 0;
    color: #333;
    font-weight: 700;
    font-size: 18px;
  }
  &-footer {
    margin: 20px 0;
  }
}
