@import './font.less';
@import './icon.less';
@import './animate.min.css';
@import './variables.less';

//begin 公共设置
html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

* {
  margin: 0;
  padding: 0;
  border: none;
  list-style: none;
  text-decoration: none;
  outline: none;
  box-sizing: border-box;
}

ol,
ul,
dl,
p {
  //清楚antd样式
  margin-bottom: 0;
}

html {
  font-size: 14px;
}

body {
  min-width: 1440px;
  // font-family: 'POPPINS-MEDIUM' !important;
  font-size: 14px;
  color: @c-mainText;
  // overflow-x: hidden;
}

a {
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}

img {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

.m-boxModel {
  position: relative;
  .f-width();
  margin: 0 auto;
  padding: 0 20px;
}

//公共组件
.swiper-container {
  position: relative;
}

.ant-notification {
  z-index: 10000;
}

.no-prefix-margin-right {
  &>.ant-input-prefix {
    margin-right: 0px;
  }
}

span.flag {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.img-box {
  position: relative;
  display: inline-block;
  vertical-align: middle;

  >span {
    width: 100% !important;
    height: 100% !important;
  }
}

.k-NotFound {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/**
* 媒体查询
*/
@media screen and (max-width: 1440px) {
  body {
    overflow-x: auto;
    min-width: 980px;
  }

  .m-boxModel {
    width: 980px;
  }
}

@media screen and (max-width: 1200px) {}

@media screen and (max-width: 1024px) {
  body {
    overflow-x: auto;
    min-width: 980px;
  }

  .m-boxModel {
    width: 980px;
  }
}

@media screen and (max-width: 768px) {
  body {
    min-width: 100%;
    overflow-x: hidden;
  }

  .m-boxModel {
    width: 100%;
    padding: 0 50rem / @media768;
  }
}