.kpy-verify {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 99;
  min-width: 178px;
  padding: 10px;
  // transition: all 0.15s;
  color: #333;
  line-height: 1.5;
  background: #fff;
  box-shadow: 0px 2px 10px #ddd;
  transform: scale(0);
  transform-origin: bottom right;
  visibility: hidden;
  border-radius: 10px;
  & > div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px;
  }
  &-show {
    right: 0px;
    bottom: 60px;
    transform: scale(1);
    visibility: visible;
  }
  .error {
    color: #f5222d;
  }
  &-error-list {
    color: #f5222d;
    font-size: 14px;
  }
}
