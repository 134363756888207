/* 引入思源黑体字体Normal */
@font-face {
  font-family: 'SourceHanSansCN-Normal';
  src: url('/static/fonts/SourceHanSansCN-Normal.eot');
  /* IE9 Compat Modes */
  src: url('/static/fonts/SourceHanSansCN-Normal.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('/static/fonts/SourceHanSansCN-Normal.woff')
      format('woff'),
    /* Modern Browsers */ url('/static/fonts/SourceHanSansCN-Normal.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/static/fonts/SourceHanSansCN-Normal.svg#SourceHanSansCN-Normal')
      format('svg');
  /* Legacy iOS */
  font-display: swap;
}
@font-face {
  font-family: 'POPPINS-LIGHT';
  src: url('../static/fonts/POPPINS-LIGHT.TTF') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'POPPINS-BOLD';
  src: url('../static/fonts/POPPINS-BOLD.TTF') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'POPPINS-MEDIUM';
  src: url('../static/fonts/POPPINS-MEDIUM.TTF') format('truetype');
  font-display: swap;
}
